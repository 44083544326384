'use client';
import { useTDispatch, useTSelector } from '@/Store';
import { changeCurrency } from '@/Store/slices/global.slice';
import { changeIsOpen } from '@/Store/slices/loginModal.slice';
import cameraIcon from '@/assets/icons/camera.svg';
import { FavoriteIcon } from '@/assets/svgComponents/favoriteIcon';
import { imgSmallURL } from '@/helpers/api';
import { curr_symbols, currencyKey } from '@/helpers/utils';
import { addToFavorite } from '@/services/addToFavorite';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import s from './style.module.scss';
import { SliderT1Props } from './type';

const sliderSettings = {
  modules: [Pagination],
  className: s.swiper,
  loop: true,
  pagination: {
    clickable: true
  }
};

export const SliderT1 = ({ data, loading }: SliderT1Props) => {
  const dispatch = useTDispatch();
  const t = useTranslations('');
  const { currency, user } = useTSelector((state) => state.global);
  const [isFavorite, setIsFavorite] = useState(false);

  const price =
    curr_symbols[currency as keyof typeof curr_symbols] +
    ' ' +
    data.priceResponse[currencyKey[currency]]?.toLocaleString(undefined, {
      maximumFractionDigits: 0
    });

  const handleLoginModal = () => {
    dispatch(changeIsOpen(true));
  };

  const handleFavorite = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    if (user) {
      setIsFavorite(!isFavorite);
      addToFavorite(id, !isFavorite);
    } else {
      handleLoginModal();
    }
  };

  useEffect(() => {
    setIsFavorite(user?.likedPropertyIds.includes(data.id));
  }, [user]);

  useEffect(() => {
    const curr = localStorage.getItem('currency');
    dispatch(changeCurrency(curr || 'USD'));
  }, [dispatch]);

  return (
    <Swiper {...sliderSettings} id='homeSlider'>
      {data.images.slice(0, 4)?.map((img: Record<string, any>, i: number) => (
        <SwiperSlide key={i}>
          <picture className='skeleton'>
            <img
              loading={loading}
              src={imgSmallURL + img.webpUrl}
              alt={`slider ${img.id}`}
              sizes='100% 50%'
              className={s.sliderItem}
            />
          </picture>
          {i === 3 && data.images.length > 4 && (
            <div className={s.seeMore}>
              <Image src={cameraIcon} width={30} height={30} alt='camera' />
              <span>
                {t('too')} {data.images.length - 4} {t('images')}
              </span>
            </div>
          )}
        </SwiperSlide>
      ))}
      {currency && <h3>{price}</h3>}
      <div className={s.photoCount}>
        <span className={s.id}>ID {data?.id}</span>
      </div>
      <div className={s.heartIcon} onClick={(e) => handleFavorite(e, data.id)}>
        <FavoriteIcon fill={isFavorite ? '#fff' : 'transparent'} />
      </div>
    </Swiper>
  );
};
